import React from "react"
import SEO from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Carousel from "../components/carousel";
import Logo from "../images/logo.kdc-small.svg";
import Callout from "../components/callout";
import Social from "../components/social";
import { Link, graphql } from "gatsby"

export default class Season extends React.Component {
    render() {
        let global = this.props.data.contentfulWebsite;
        return (
            <Layout pathname={this.props.location.pathname}>
                <SEO metaTitle={`Season ${this.props.pageContext.season} - ${global.title}`} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
                <div className="o-wrapper o-wrapper--small o-content o-container o-content-container">
                    <img className="c-logo c-logo--small" src={Logo} alt={global.title}></img>
                    <div className="o-child-spacer o-child-spacer--small">
                        {this.props.pageContext.seasons.map((season, index) => {
                            return (
                                <Link key={index} to={`/videos/season${season}`} className={`c-tab ${this.props.pageContext.season === season ? 'c-tab--active' : ''}`}>Season {season}</Link>
                            )
                        })}
                    </div>
                    <div className="o-content-spacer o-content-spacer--small">
                        <Carousel episodes={this.props.pageContext.videos} season={true}></Carousel>
                    </div>
                </div>
                <div className="o-content-container">
                    {this.props.data.allContentfulCard.nodes[0] ? <Callout callout={this.props.data.allContentfulCard.nodes[0]} /> : ''}
                    {global.showSocialPosts ? (
                        <div className="o-wrapper o-content o-container o-content-container">
                            <Social title={global.socialPostsTitle} posts={this.props.data.allContentfulSocialPost.nodes} />
                        </div>
                    ) : ''}
                    {this.props.data.allContentfulCard.nodes.map((callout, index) => (
                        index > 0 ? <Callout key={index} callout={callout} /> : ''
                    ))}
                </div>
            </Layout>
        );
    }
};

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Kneading Dough Canada"}) {
        author
        description
        title
        socialPostsTitle
        showSocialPosts
        shareImage {
            file {
                url
            }
        }
    }
    allContentfulCard(filter: {isDefault: {eq: "Yes"}}) {
        nodes {
            buttonText
            url
            title
            disclaimer {
                raw
            }
            subtitle
        }
    }
    allContentfulSocialPost(sort: {fields: updatedAt, order: DESC}) {
        nodes {
            link
            altText
            image {
                resize(width: 350, height: 350) {
                    src
                }
            }
        }
    }
}
`;
